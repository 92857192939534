import './src/scss/theme.scss'
import('../../src/js/app')

var moment = require('moment');

require('owl.carousel');
require('daterangepicker');

$(document).ready(function () {
    $('.calendar .owl-carousel').owlCarousel({
        loop: true,
        margin: 4,
        nav: true,
        dots: false,
        stagePadding: 30,
        navText: [
            '<i class=\'fa fa-fw fa-chevron-left\'></i>',
            '<i class=\'fa fa-fw fa-chevron-right\'></i>'
        ],
        autoplay: true,
        autoplayHoverPause: true,
        items: 1
    })

    $('.owl-carousel.owl-testimonials').owlCarousel({
        margin: 30,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                stagePadding: 70,
                loop: true,
                nav: true,
                items: 1,
                margin: 10
            },
            600: {
                stagePadding: 50,
                loop: true,
                nav: true,
                items: 2
            },
            1000: {
                loop: true,
                nav: false,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                freeDrag: false,
                items: 2
            }
        }
    });

    // daterange picker
    var searchParams = new URLSearchParams(window.location.search);
    var startDate = searchParams.get('start') ? searchParams.get('start') : moment();
    var endDate = searchParams.get('end') ? searchParams.get('end') : moment().startOf('day').add(3, 'month');
    $('input[name="dates"]').daterangepicker({
        locale: {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Toepassen",
            cancelLabel: "Annuleren",
            fromLabel: "Van",
            toLabel: "Tot",
            customRangeLabel: "Aangepast",
            weekLabel: "W",
            daysOfWeek: [
                "Zo",
                "Ma",
                "Di",
                "Wo",
                "Do",
                "Vr",
                "Za"
            ],
            monthNames: [
                "Januari",
                "Februari",
                "Maart",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Augustus",
                "September",
                "Oktober",
                "November",
                "December"
            ],
            firstDay: 1
        },
        startDate: startDate,
        endDate: endDate,
        opens: "left",
        ranges: {
            'Vandaag': [moment(), moment()],
            'Deze week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
            'Deze maand': [moment().startOf('month'), moment().endOf('month')],
            'Volgende maand': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
            '3 maanden': [moment().startOf('month'), moment().add(2, 'month').endOf('month')]
        }

    }, function(start, end, label) {
        if ('URLSearchParams' in window) {
            var selectedRangeStart = start.format('DD-MM-YYYY');
            var selectedRangeEnd = end.format('DD-MM-YYYY');
            searchParams.set("start", selectedRangeStart);
            searchParams.set("end", selectedRangeEnd);
            window.location.search = searchParams.toString();
        }
    });

});
